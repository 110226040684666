<template>
  <div v-auto-animate>
    <section
      v-if="!hasOrder && isDomContentLoaded"
      class="relative mb-7 mt-16 flex flex-col items-center rounded-md bg-special-primary px-8 pb-12 pt-32 max-md:mx-7 lg:mx-auto lg:mt-24 lg:w-[980px] lg:flex-row lg:px-0 lg:py-10"
    >
      <figure class="absolute -top-12 left-1/2 -translate-x-1/2 transform md:-top-4 md:left-6 md:transform-none">
        <img
          :src="content.image.path.desktop"
          :alt="content.image.alt"
          class="w-[108px] lg:w-[108px]"
          :width="content.image.width"
        />
      </figure>
      <hgroup class="flex flex-col text-center text-black lg:pl-44 lg:text-left">
        <h2 class="mb-8 text-4xl font-bold leading-10 lg:mb-2 lg:text-xl lg:leading-7" v-html="content.title"></h2>
        <h3 class="text-lg leading-normal lg:text-lg lg:leading-7" v-html="content.subtitle"></h3>
      </hgroup>
      <a
        class="p-button p-button-outlined !bg-white font-medium !text-neutral-gray7 max-md:mt-5 max-md:!px-20 lg:ml-auto lg:mr-11"
        :href="content.cta.url"
        :title="content.cta.label"
      >
        {{ content.cta.label }}
      </a>
    </section>
  </div>
</template>

<script setup>
import { useStore } from "@nanostores/vue";
import { userHasOrder } from "@store/store.ts";
import { computed, nextTick, onMounted, ref } from "vue";

const hasOrder = useStore(userHasOrder);

const isDomContentLoaded = ref(false);

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
});

const content = computed(() => props.content);

onMounted(() => {
  nextTick(() => {
    isDomContentLoaded.value = true;
  });
});
</script>

<style lang="scss" scoped></style>
